import React, { useState, useContext } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import noImage from "../../../scss/styles/images/noimage.png";
import Icons from "../../../scss/prh-icon-sprite.svg";

const DesignCarousel = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;
  const applicationNumber = fields.applicationNumber;
  const [index, setIndex] = useState(props.index);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  const toggleCarousel = (dir) => {
    const max = props.viewCount - 1;
    setDirection(dir);
    if (dir === "next") {
      if (index + 1 > max) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      if (index - 1 < 0) {
        setIndex(max);
      } else {
        setIndex(index - 1);
      }
    }
  };

  return (
    <Row>
      {props.viewCount > 1 && (
        <Col
          xs={1}
          style={{
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => toggleCarousel("prev")}
        >
          <a className="left carousel-control">
            <svg
              alt={translate("design_navigation_carousel_left")}
              className="prh-icon prh-icon--md"
              style={{ stroke: "#000" }}
            >
              <use xlinkHref={`${Icons}#chevron-left`}></use>
            </svg>
          </a>
        </Col>
      )}
      <Col>
        <Carousel
          tabIndex="0"
          keyboard={true}
          interval={null}
          activeIndex={index}
          controls={false}
          direction={direction}
          onSelect={handleSelect}
          slide={false}
          indicators={false}
        >
          {fields.designs[props.currentDesign - 1].designViews.map((view) =>
            view.allowPublication === props.registered ? (
              <Carousel.Item
                key={applicationNumber + props.currentDesign + view.viewNumber}
              >
                <img
                  className="img-fluid img-height"
                  alt=""
                  src={ view.viewUrl }
                  key={
                    applicationNumber + props.currentDesign + view.viewNumber
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noImage;
                  }}
                />
              </Carousel.Item>
            ) : null
          )}
        </Carousel>
      </Col>
      {props.viewCount > 1 && (
        <Col
          xs={1}
          style={{
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => toggleCarousel("next")}
        >
          <a className="right carousel-control">
            <svg
              alt={translate("design_navigation_carousel_right")}
              className="prh-icon prh-icon--md"
              style={{ stroke: "#000" }}
            >
              <use xlinkHref={`${Icons}#chevron-right`}></use>
            </svg>
          </a>
        </Col>
      )}
    </Row>
  );
};
export default DesignCarousel;
