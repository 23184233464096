import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import BasicInfo from "./infoComponents/DsBasicInfo";
import DetailedInfo from "./infoComponents/DsDetailedInfo";
import PersonInfo from "../common/PersonInfo";
import DesignerInfo from "./infoComponents/DsDesignerInfo";
import PublicationsInfo from "./infoComponents/DsPublicationsInfo";
import CorrespondenceInfo from "../common/CorrespondenceInfo";
import RelatedDossierInfo from "./infoComponents/DsRelatedDossierInfo";
import { formatDate } from "../../helpers/helpers";
import ClassificationInfo from "./infoComponents/DsClassificationInfo";
import PageNotFound from "../errors/PageNotFound";

const DesignDetails = props => {
  const { translate } = useContext(I18nContext);
  const data = props.design.data;

  const findOldestDate = list => {
    if (!list || list.length < 1) {
      return "";
    }
    let dates = [];
    list.forEach(item => item.priorityDate ? dates.push(item.priorityDate): null);
    return dates.sort()[0];
  };

  const handlePrint = () => {
    window.print();
  };

  const basicInfo = {
    applicationDate: formatDate(data.applicationDate),
    dossierStatus: data.dossierStatus,
    applicationNumber: data.applicationNumber,
    oppositionPeriodStartDate: formatDate(data.oppositionPeriodStartDate),
    oppositionPeriodEndDate: formatDate(data.oppositionPeriodEndDate),
    registrationDate: formatDate(data.registrationDate),
    usageObligationStartDate: formatDate(data.usageObligationStartDate),
    expirationDate: formatDate(data.expirationDate),
    priorityDate: formatDate(findOldestDate(data.priorities)),
    productTitle: data.productTitle,
    registrationNumber: data.registrationNumber,
    priorities: data.priorities,
    locarnos: data.locarnos,
    defermentExpiration: formatDate(data.defermentExpiration),
    wipoLink: data.wipoLink,
    filingNumber: data.filingNumber
  };

  const fetchDesign = props.fetchDesign;
  let path = "";
  props.match.params.registrationNumber
    ? (path =
        "design/" +
        props.match.params.applicationNumber +
        "/" +
        props.match.params.registrationNumber)
    : (path = "design/" + props.match.params.applicationNumber);

  useEffect(() => {
    fetchDesign(path);
  }, []);

  if (data.length < 1) {
    if(props.design.error)
      return (<PageNotFound title="design_not_found" description="design_not_found_description"/>);

    return null;
  }

  return (
    <Container className={"mt-2 mb-4"}>
      <Row className="align-items-center">
        <Col xs={6} className="pr-0">
          <h2 className="m-0">{translate("design.portlet.design.details")}</h2>
        </Col>
        <Col xs={6} className="d-flex justify-content-end d-print-none">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handlePrint}
          >
            <span>{translate("design.portlet.print")}</span>
          </button>
        </Col>
      </Row>
      <BasicInfo data={basicInfo} />
      <DetailedInfo data={data} />

      <PersonInfo
        title={translate("design.portlet.applicant.registered")}
        persons={data.applicants}
      />

      <PersonInfo
        title={translate("design.portlet.representative")}
        persons={data.representatives}
        type={"representative"}
      />
      <DesignerInfo
        title={translate("design.portlet.designer")}
        persons={data.designers}
      />
      <ClassificationInfo
        title={translate("design.portlet.classification")}
        locarnos={data.locarnos}
      />
      <CorrespondenceInfo data={data.correspondence} />
      <PublicationsInfo data={data.publications} />
      <RelatedDossierInfo data={data.relatedDossiers} />
      
    </Container>
  );
};

export default withRouter(DesignDetails);
