import React, { useContext, useState, useRef } from "react";
import { I18nContext } from "../../../i18n";
import { Row, Col } from "react-bootstrap";
import ToolTip from "../../common/ToolTip";
import Icons from "../../../scss/prh-icon-sprite.svg";

const ResultSorter = (props) => {
  const { translate } = useContext(I18nContext);
  const [sorter, setSorter] = useState("");
  const [ascending, setAscending] = useState(true);
  const ascendingRef = useRef();

  const handleClick = (event) => {
    if (event.target.value === sorter) {
      ascendingRef.current = !ascending;
      setAscending(!ascending);
    } else {
      ascendingRef.current = true;
      setSorter(event.target.value);
      setAscending(true);
    }
    sortResults(event.target.value);
  };

  const handleChevronClick = () => {
    ascendingRef.current = !ascending;
    setAscending(!ascending);
    sortResults(sorter);
  };

  const findOldestPriority = (priorities) => {
    return priorities.sort((a, b) =>
      a.priorityDate && b.priorityDate
        ? a.priorityDate.localeCompare(b.priorityDate)
        : 0
    )[0];
  };

  const listSortWithEmpty = (a, b) => {
    if (a.length === 0 && ascendingRef.current) {
      return 1;
    }
    if (b.length === 0 && ascendingRef.current) {
      return -1;
    }
    if (a.length === 0 && !ascendingRef.current) {
      return -1;
    }
    if (b.length === 0 && !ascendingRef.current) {
      return 1;
    }
  };

  const stringSortWithEmptyValue = (a, b, newSorter) => {
    if (!a[newSorter] && ascendingRef.current) {
      return 1;
    }
    if (!b[newSorter] && ascendingRef.current) {
      return -1;
    }
    if (!a[newSorter] && !ascendingRef.current) {
      return -1;
    }
    if (!b[newSorter] && !ascendingRef.current) {
      return 1;
    }
  };

  const sortResults = (newSorter) => {
    const oldList = [...props.data.results];

    let sortedList = [];
    switch (newSorter) {
      case "applicantName":
        sortedList = oldList.sort((a, b) => {
          if (a.applicants.length === 0 || b.applicants.length === 0) {
            return listSortWithEmpty(a.applicants, b.applicants);
          }
          return a.applicants[0].name.localeCompare(b.applicants[0].name);
        });
        break;
      case "representativeName":
        sortedList = oldList.sort((a, b) => {
          if (
            a.representatives.length === 0 ||
            b.representatives.length === 0
          ) {
            return listSortWithEmpty(a.representatives, b.representatives);
          }
          return a.representatives[0].name.localeCompare(
            b.representatives[0].name
          );
        });
        break;
      case "priorityDate":
        sortedList = oldList.sort((a, b) => {
          if (a.priorities.length === 0 || b.priorities.length === 0) {
            return listSortWithEmpty(a.priorities, b.priorities);
          }
          const priorityA = findOldestPriority(a.priorities);
          const priorityB = findOldestPriority(b.priorities);
          if (
            priorityA.priorityDate == null ||
            priorityB.priorityDate == null
          ) {
            return listSortWithEmpty(a.priorities, b.priorities);
          }
          return priorityA.priorityDate.localeCompare(priorityB.priorityDate);
        });
        break;
      default:
        sortedList = oldList.sort((a, b) => {
          if (!a[newSorter] || !b[newSorter]) {
            return stringSortWithEmptyValue(a, b, newSorter);
          }
          return a[newSorter].localeCompare(b[newSorter]);
        });
    }

    if (!ascendingRef.current) {
      sortedList.reverse();
    }
    const sortedResults = {
      results: sortedList,
      totalResults: props.data.totalResults,
    };

    props.setShowItems(sortedResults);
  };

  const SortField = (props) => (
    <Row
      className="m-0 pt-1 pb-2"
      style={{ backgroundColor: props.value === sorter ? "#ebebeb" : "white" }}
    >
      <Col xs={10}>
        <button
          onClick={handleClick}
          value={props.value}
          id={props.value}
          className={
            props.value === sorter ? "select-btn-selected" : "select-btn"
          }
          aria-pressed={props.value === sorter}
        >
          {props.text}
        </button>
      </Col>
      <Col xs={2}>
        <button
          onClick={handleChevronClick}
          className={
            props.value === sorter ? "select-btn-selected" : "select-btn"
          }
        >
          {props.value === sorter ? (
            ascending ? (
              <svg
                alt={translate("design_sorting_ascending")}
                className="sort-chevron-icon p-0"
              >
                <use xlinkHref={`${Icons}#chevron-down`}></use>)
              </svg>
            ) : (
              <svg
                alt={translate("design_sorting_descending")}
                className="sort-chevron-icon p-0"
              >
                <use xlinkHref={`${Icons}#chevron-up`}></use>)
              </svg>
            )
          ) : null}
        </button>
      </Col>
    </Row>
  );

  return (
    <div
      aria-describedby="dsSorterTooltip"
      style={{ backgroundColor: "white" }}
    >
      <Row className="m-0 p-2">
        <Col
          xs={"auto"}
          className="pr-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h4 className="selection-title m-0">{translate("design_sorting")}</h4>
        </Col>
        <Col xs={"auto"} className="pl-0 pr-0">
          <ToolTip
            text={translate("design_infotext_sorting")}
            id="dsSorterTooltip"
          />
        </Col>
        <Col></Col>
      </Row>
      <SortField
        text={translate("design.portlet.current.status")}
        value="dossierStatus"
      />
      {props.path === "design" && (
        <SortField
          text={translate("design.portlet.priority.date")}
          value="priorityDate"
        />
      )}
      <SortField
        text={translate("design.portlet.application.date")}
        value="applicationDate"
      />
      <SortField
        text={translate("design.portlet.registration.date")}
        value="registrationDate"
      />
      {props.path === "design" && (
        <SortField
          text={translate("design.portlet.expiry.date")}
          value="expirationDate"
        />
      )}
      <SortField
        text={translate("design.portlet.applicant")}
        value="applicantName"
      />
      {props.path === "design" && (
        <SortField
          text={translate("design.portlet.representative")}
          value="representativeName"
        />
      )}
    </div>
  );
};

export default ResultSorter;
