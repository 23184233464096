import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MasonryView from "./MasonryView";
import PrhCardView from "./PrhCardView";
import TableView from "./TableView";
import ResultBar from "./ResultBar/ResultBar";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import SortersAndFilters from "./sortersAndFilters/SortersAndFilters";

const SearchResults = props => {
  const [showItems, setShowItems] = useState([]);
  /* const [showSortersAndFilters, setShowSortersAndFilters] = useState(false); */
  /* const [filtersSelected, setFiltersSelected] = useState(false); */
  const size = UseWindowSize();

  /* const toggleShowSortersAndFilters = () => {
    setShowSortersAndFilters(!showSortersAndFilters);
  }; */

  useEffect(() => {
    setShowItems(props.data);
  }, [props.data]);

  const changeView = newView => {
    props.setViewMode(newView);
  };

  const changeItems = items => {
    setShowItems(items);
  };

  if (props.loading) {
    return (
      <div className="p-4" style={{ textAlign: "center" }}>
        <Spinner className="spinner-border" />
      </div>
    );
  }

  if (props.data.length === 0) {
    return null;
  }

  if (showItems.length === 0) {
    return null;
  }

  return (
    <Container>
      <ResultBar
        data={props.data}
        changeView={changeView}
        setShowItems={changeItems}
        path={props.path}
        searchParams={props.searchParams}
        setSearchParams={props.setSearchParams}
        fetchSearchResults={props.fetchSearchResults}
        toggleShowSortersAndFilters={props.toggleShowSortersAndFilters}
        showSortersAndFilters={props.showSortersAndFilters}
        filtersSelected={props.filtersSelected}
        setFiltersSelected={props.setFiltersSelected}
      />
      <Row>
        {props.showSortersAndFilters && size.width >= bootStrapGrid.lg ? (
          <Col lg={0} xl={5} className="mt-3 pr-0">
            <SortersAndFilters
              setShowItems={changeItems}
              data={props.data}
              path={props.path}
              searchParams={props.searchParams}
              setSearchParams={props.setSearchParams}
              fetchSearchResults={props.fetchSearchResults}
              setFiltersSelected={props.setFiltersSelected}
            />
          </Col>
        ) : null}

        <Col
          lg={12}
          xl={
            props.showSortersAndFilters && size.width >= bootStrapGrid.lg
              ? 7
              : 12
          }
        >
          {props.viewMode === "prhCard" ? (
            <PrhCardView
              changeView={changeView}
              data={showItems}
              path={props.path}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              pathParams = { props.pathParams }
            />
          ) : null}
          {props.viewMode === "table" ? (
            <TableView
              changeView={changeView}
              data={showItems}
              path={props.path}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              pathParams = { props.pathParams }
            />
          ) : null}
          {props.viewMode === "masonry" ? (
            <MasonryView
              changeView={changeView}
              data={showItems}
              onChangePage={props.onChangePage}
              currentPage={props.masonryPage}
              pathParams = { props.pathParams }/>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchResults;
