import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import UseWindowSize from "../../../customHooks/useWindowSize";
import ResultSorter from "./ResultSorter";
import DsStatusFilter from "./DsStatusFilter";
import { bootStrapGrid } from "../../../constants/constants";
import {
  allDossierStatuses
} from "../../SearchForm/FormConstants";

const SortersAndFilters = (props) => {
  const size = UseWindowSize();

  useEffect(() => {
    filterSelectionActive();
  }, [props.data]);

  const onStatusFilterChange = (dossierStatus) => {
    const newParams = { ...props.searchParams, dossierStatus };
    props.fetchSearchResults(newParams, props.path);
    filterSelectionActive();
  };

  const filterSelectionActive = () => {
    const statusFilters = allDossierStatuses;
    let allSelected = true;
    statusFilters.forEach(item => {
      if (!props.searchParams.dossierStatus.includes(item)) {
        allSelected = false;
      }
    });
    props.setFiltersSelected(!allSelected);
  };

  const singleColumnView = (
    <Row>
      <Col className="pb-1" xs={12}>
        <ResultSorter
          setShowItems={props.setShowItems}
          data={props.data}
          path={props.path}
        />
      </Col>
      <Col xs={12}>
        <DsStatusFilter
          changeFilters={onStatusFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
    </Row>
  );
  const doubleColumnView = (
    <Row>
      <Col className="pr-1" md={6}>
        <div className="pb-1">
          <ResultSorter
            setShowItems={props.setShowItems}
            data={props.data}
            path={props.path}
          />
        </div>
      </Col>
      <Col className="pl-1" xs={12} md={6}>
        <DsStatusFilter
          changeFilters={onStatusFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
          path={props.path}
        />
      </Col>
    </Row>
  );
  return (
    <Container className="p-1" style={{ backgroundColor: "#d9d9d9" }}>
      {size.width < bootStrapGrid.sm || size.width >= bootStrapGrid.lg
        ? singleColumnView
        : doubleColumnView}
    </Container>
  );
};

export default SortersAndFilters;
