import React, { useContext, useEffect } from "react";
import { I18nContext } from "../i18n";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import BasicSearch from "../components/SearchForm/BasicSearch";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";
import SurveyModal from "../components/yearlySurvey/SurveyModal";

const MainPage = (props) => {
  const { translate, dispatch } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("search_design") + " | PRH";

  useEffect(() => {
    props.pathParams.updateLanguage();
    props.setActiveLink("Home");
  }, []);

  return (
    <>
      <Container fluid="true" className="flex-wrapper m-0 p-0">
        <div>
          <div id="skip">
            <a href="#main_content">{translate("jumplink_to_content")}</a>
          </div>
          {size.width < bootStrapGrid.sm ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          <main id="main_content" tabIndex="-1">
            <BasicSearch
              fetchSearchResults={props.fetchSearchResults}
              expand={true}
              setSearchParams={props.setSearchParams}
              searchParams={props.searchParams}
              path="design"
              pathParams={props.pathParams}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(MainPage);
