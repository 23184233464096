import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Container } from "react-bootstrap";

const SearchBar = props => {
  const { translate } = useContext(I18nContext);

  return (
    <Container
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}
      className="mt-2"
    >
      <button
        data-cy="search-btn"
        className="btn btn-primary"
        onClick={() => window.history.back()}
      >
        {translate("design.portlet.edit.search.button")}
      </button>
      <button
        data-cy="search-btn"
        id="filter"
        style={
          props.filtersSelected
            ? { backgroundColor: "#c2529f", borderColor: "#c2529f" }
            : {}
        }
        className={
          props.filtersSelected
            ? "btn btn-primary ml-3"
            : "btn btn-secondary ml-3"
        }
        onClick={() => props.toggleShowSortersAndFilters()}
      >
        {props.filtersSelected ? translate("design_filters_on") : translate("design_filter_and_sort")}
      </button>
    </Container>
  );
};

export default SearchBar;
