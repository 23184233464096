import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import DataField from "../../common/DataField";

const ClassificationInfo = props => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <h3>{props.title}</h3>
      {props.locarnos.length < 1 ? (
        <span>-</span>
      ) : (
        props.locarnos.map(locarno => (
          <Row key={locarno.ordinal}>
            <Col xs={6} md={4}>
              <DataField
                title={translate("design.portlet.locarno.class")}
                value={locarno.class}
              />
            </Col>

            <Col xs={12} md={4}>
              <DataField
                title={translate("design.portlet.locarno.sub.class")}
                value={locarno.subClass}
              />
            </Col>
            <Col xs={6} md={4}>
              <DataField
                title={translate("design.portlet.ncl.version")}
                value={locarno.version}
              />
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default ClassificationInfo;
