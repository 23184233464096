import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { formatDate, formatDesignPath } from "../../helpers/helpers";
import DataField from "../common/DataField";
import noImage from "../../scss/styles/images/noimage.png";
import { Link, withRouter } from "react-router-dom";

const PrhCard = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;
  const design = props.designData;

  const oldestPriority =
    fields.priorities.sort((a, b) =>
      a.priorityDate && b.priorityDate
        ? a.priorityDate.localeCompare(b.priorityDate)
        : 0
    )[0] || {};

  return (
    <div data-cy="card" className="prh-card">
      <Link
        aria-label={design.title + " " + fields.applicationNumber}
        className="p-0"
        to={{
          pathname: props.pathParams.buildPath( 'design/' + formatDesignPath( fields ))
        }}
      >
        <div
          className="row smooth-5 m-1 mt-3 mb-3 p-2 p-sm-3 text-left"
          data-cy={design.title + "_link"}
        >
          <div className="col-5 col-md-3">
            <div className="row">
              <div className="col-12">
                <img
                  data-cy="image"
                  className="img-fluid"
                  src={design.dominantViewImageUrl}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noImage;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-7 col-md-3">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("design.portlet.result.header.title")}
                  value={design.title || "-"}
                  data_cy="designtitle"
                />
              </div>
              <div className="col-12">
                <DataField
                  title={translate(
                    "design.portlet.result.header.application.number"
                  )}
                  value={
                    <span className="card-link p-0">
                      {fields.applicationNumber}
                    </span>
                  }
                  data_cy="application_number"
                />
              </div>
              <div className="col-12">
                <DataField
                  title={translate(
                    "design.portlet.result.header.register.number"
                  )}
                  value={fields.registrationNumber || "-"}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-6 col-md-12 d-none d-md-block">
                <DataField
                  title={translate("design.portlet.priority.date")}
                  value={formatDate(oldestPriority.priorityDate) || "-"}
                />
              </div>
              <div className="col-6 col-md-12">
                <DataField
                  title={translate("design.portlet.current.status")}
                  value={
                    translate(
                      "design.status." + fields.dossierStatus.toLowerCase()
                    ) || "-"
                  }
                />
              </div>
              <div className="col-6 col-md-12 d-none d-md-block">
                <DataField
                  title={translate("design.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
              <div className="col-6 col-md-12 d-md-none d-block">
                <DataField
                  title={translate("design.portlet.classes")}
                  value={fields.locarnos
                    .map((item) => item.classNumber + "-" + item.subClassNumber)
                    .join(", ")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-6 col-md-12 d-md-none d-block">
                <DataField
                  title={translate("design.portlet.priority.date")}
                  value={formatDate(oldestPriority.priorityDate) || "-"}
                />
              </div>
              <div className="col-6 col-md-12">
                <DataField
                  title={translate("design.portlet.application.date")}
                  value={formatDate(fields.applicationDate) || "-"}
                />
              </div>

              <div className="col-6 col-md-12 d-none d-md-block">
                <DataField
                  title={translate("design.portlet.classes")}
                  value={fields.locarnos
                    .map((item) => item.classNumber + "-" + item.subClassNumber)
                    .join(", ")}
                />
              </div>
              <div className="col-6 col-md-12 d-none d-md-block">
                {fields.representatives.length === 0 ? null : (
                  <DataField
                    title={translate("design.portlet.representative")}
                    value={fields.representatives
                      .map((item) => item.name)
                      .join(" ")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-md-none">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("design.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default withRouter(PrhCard);
