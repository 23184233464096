import React from 'react';
import Container from 'react-bootstrap/Container';
import MobileNavbar from '../components/header/MobileNavbar';
import ExpandedNavbar from '../components/header/ExpandedNavbar';
import '../scss/styles/app.scss';
import UseWindowSize from '../customHooks/useWindowSize';

const Masonry = ( props ) => {
  const size = UseWindowSize();
  return (
    <>
      <Container fluid="true" className="m-0 p-0">
        {
          size.width < 1000 ?
            <MobileNavbar pathParams = { props.pathParams }/> :
            <ExpandedNavbar pathParams = { props.pathParams }/>
        }

        <Container className=" mt-5">
          <div class="table-responsive">
            <table
              id=""
              role="table"
              aria-colcount="4"
              aria-rowcount="-1"
              className="table table-hover table-stacked-md prh-table-view"
            >
              <thead>
                <tr>
                  <th aria-colindex="1">
                    Hakemus- ja
                    <br />
                    rekisterinro
                  </th>
                  <th aria-colindex="2">Mallit</th>
                  <th aria-colindex="3">Luokat</th>
                  <th aria-colindex="4">Etuoikeus- ja hakemispvm</th>
                  <th aria-colindex="5">Rekisteröinti- ja erääntymispvm</th>
                  <th aria-colindex="6">Nykyinen tila</th>
                  <th aria-colindex="7">Hakija</th>
                </tr>
              </thead>

              <tbody role="rowgroup">
                <tr aria-rowindex="1" role="row">
                  <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
                    <div>M20110085</div>
                    <div>25077</div>
                  </td>
                  <td aria-colindex="2" data-label="mallit" role="cell">
                    <div>1. Pullo</div>
                    <div>2. Pullo</div>
                    <div>3. Pullo</div>
                    <div>4. Pullo</div>
                  </td>
                  <td aria-colindex="3" data-label="Luokkanumero" role="cell">
                    <div>09-01</div>
                  </td>
                  <td aria-colindex="4" data-label="Kohderyhmä" role="cell">
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td
                    aria-colindex="5"
                    data-label="Hakemis- ja hyväksymispäivä"
                    role="cell"
                  >
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td aria-colindex="6" data-label="Nykyinen tila" role="cell">
                    <div>Kansallinen; Rekisteröity</div>
                  </td>
                  <td aria-colindex="7" data-label="Haltija" role="cell">
                    <div>SPIRITS INTERNATIONAL B.V. </div>
                  </td>
                </tr>

                <tr aria-rowindex="2" role="row">
                  <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
                    <div>M20100131</div>
                    <div>25010</div>
                  </td>
                  <td
                    aria-colindex="2"
                    data-label="Merkin kuva ja -sana"
                    role="cell"
                  >
                    <div>1. Pullo</div>
                  </td>
                  <td aria-colindex="4" data-label="Luokkanumero" role="cell">
                    <div>09-01</div>
                  </td>
                  <td aria-colindex="5" data-label="Kohderyhmä" role="cell">
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td
                    aria-colindex="5"
                    data-label="Hakemis- ja hyväksymispäivä"
                    role="cell"
                  >
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td aria-colindex="7" data-label="Nykyinen tila" role="cell">
                    <div>Kansallinen; Rekisteröity</div>
                  </td>
                  <td aria-colindex="8" data-label="Haltija" role="cell">
                    <div>Eckes-Granini Finland Oy Ab</div>
                  </td>
                </tr>

                <tr aria-rowindex="3" role="row">
                  <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
                    <div>M20080103 </div>
                    <div>24553</div>
                  </td>
                  <td
                    aria-colindex="2"
                    data-label="Merkin kuva ja -sana"
                    role="cell"
                  >
                    <div>1. Pullo</div>
                  </td>
                  <td aria-colindex="4" data-label="Luokkanumero" role="cell">
                    <div>09-01</div>
                  </td>
                  <td aria-colindex="5" data-label="Kohderyhmä" role="cell">
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td
                    aria-colindex="5"
                    data-label="Hakemis- ja hyväksymispäivä"
                    role="cell"
                  >
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td aria-colindex="7" data-label="Nykyinen tila" role="cell">
                    <div>Kansallinen; Rekisteröity</div>
                  </td>
                  <td aria-colindex="8" data-label="Haltija" role="cell">
                    <div>Oy Gust. Ranin</div>
                  </td>
                </tr>

                <tr aria-rowindex="4" role="row">
                  <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
                    <div>M20000884</div>
                    <div>22134</div>
                  </td>
                  <td
                    aria-colindex="2"
                    data-label="Merkin kuva ja -sana"
                    role="cell"
                  >
                    <div>1. Pullo / suljettava säiliö</div>
                  </td>
                  <td aria-colindex="4" data-label="Luokkanumero" role="cell">
                    <div>09-01</div>
                  </td>
                  <td aria-colindex="5" data-label="Kohderyhmä" role="cell">
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td
                    aria-colindex="5"
                    data-label="Hakemis- ja hyväksymispäivä"
                    role="cell"
                  >
                    <div>01.01.1970</div>
                    <div>01.01.1970</div>
                  </td>
                  <td aria-colindex="7" data-label="Nykyinen tila" role="cell">
                    <div>Kansallinen; Rekisteröity</div>
                  </td>
                  <td aria-colindex="8" data-label="Haltija" role="cell">
                    <div>Lidl Stiftung &amp; Co. KG</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Masonry;
