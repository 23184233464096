import React, { useContext, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import { initialSearchParams, bootStrapGrid } from "../../constants/constants";
import { allDossierStatuses } from "./FormConstants";
import ToolTip from "../common/ToolTip";
import UseWindowSize from "../../customHooks/useWindowSize";
import filterIcon from "../../scss/styles/images/FilterViewIcon.svg";
import filtersSelectedIcon from "../../scss/styles/images/ActiveFilterViewIcon.svg";
import Icons from "../../scss/prh-icon-sprite.svg";

const BasicSearch = (props) => {
  const { translate } = useContext(I18nContext);
  const [validationError, setValidationError] = useState(false);
  const size = UseWindowSize();
  const handleSubmit = () => {
    const newParams = {
      ...initialSearchParams,
      basicSearch: props.searchParams.basicSearch,
      dossierStatus: allDossierStatuses,
    };
    if (newParams.basicSearch.length < 1000) {
      props.setSearchParams(newParams);
      props.fetchSearchResults(newParams, props.path);

      props.history.push( props.pathParams.buildPath( 'results' ));
    } else {
      setValidationError(true);
    }
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const onChangeHandler = (event) => {
    let newParams = { ...props.searchParams };
    newParams.basicSearch = event.currentTarget.value;
    props.setSearchParams(newParams);
  };

  if (!props.searchParams) {
    return null;
  }

  const searchField = (
    <div className="searchfield-container p-4">
      <div className="input-group">
        <input
          aria-label={translate("search_design")}
          aria-describedby="searchInstructions"
          autoComplete="off"
          type="text"
          className={
            !validationError
              ? "form-control align-self-center border border-dark"
              : "form-control align-self-center is-invalid"
          }
          onKeyUp={(event) => keyPressHandler(event)}
          onChange={onChangeHandler}
          id="search_word"
          data-cy="search_word"
          value={props.searchParams.basicSearch}
          placeholder={translate("design_basic_search_placeholder")}
        />
        <div className="input-group-append">
          <button
            data-cy="search-btn"
            className="btn btn-primary"
            onClick={() => handleSubmit()}
          >
            {translate("design.portlet.search.button")}
          </button>
        </div>
      </div>
      {props.path !== "tmr" ? (
        <Row>
          <Col className="pt-3">
            <Link to={ props.pathParams.buildPath( 'search' )}>
              <span className="" style={{ fontSize: "1.1rem" }}>
                {translate("design.portlet.advanced.search")}
              </span>
            </Link>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );

  const infotext = (
    <Container className={size.width < bootStrapGrid.sm ? "pt-2" : "pt-4"}>
      <div className="pt-4 pb-3">
        <Row className="pb-3 pt-4">
          <Col className={size.width < bootStrapGrid.sm ? "" : "pt-4"}>
            <h3 className="text-blue mt-2 pt-3">
              {translate("design_search_info")}
            </h3>
          </Col>
        </Row>
        <Row className="pr-3">
          <Col xs={12} className="">
            <span htmlFor="search_word" className="mb-0 pb-0">
              {translate("search_free_text_search")}
            </span>
          </Col>
          <Col>
            <a href="https://euipo.europa.eu/eSearch/">
              <svg
                aria-labelledby="euipo_link"
                className="prh-icon prh-icon--xs"
                style={{ stroke: "#035ca7" }}
              >
                <title id="euipo_link">
                  {translate("design_external_link")}
                </title>
                <use xlinkHref={`${Icons}#external-link`}></use>
              </svg>
              <span className="ml-1" style={{ fontSize: "1.1rem" }}>
                {translate("link_to_euipo")}
              </span>
            </a>
          </Col>
        </Row>
      </div>
    </Container>
  );

  const expandedComponent = (
    <div>
      <Row className="searchfield-background  mr-0">
        <Col className="pr-0">
          <Container>
            <Row className="align-items-center">
              <Col xs="auto" className="pr-0">
                <h2 className="text-white mt-2 pt-4 pb-4">
                  {translate("search_design")}
                </h2>
              </Col>
              <Col xs="auto" className="pl-0 pt-1">
                <ToolTip
                  whiteCircle={true}
                  text={translate("design_infotext_basicsearch")}
                  id="searchInstructions"
                  placement={size.width < bootStrapGrid.sm ? "bottom" : "right"}
                />
              </Col>
            </Row>
            {searchField}
          </Container>
        </Col>
      </Row>
      {infotext}
    </div>
  );

  return (
    <div>
      {props.expand ? (
        expandedComponent
      ) : (
        <Container>
          <div className="input-group pt-3">
            <input
              aria-label={translate("search_design")}
              type="text"
              autoComplete="off"
              className="form-control align-self-center border border-dark"
              onKeyUp={(event) => keyPressHandler(event)}
              onChange={onChangeHandler}
              id="search_word"
              data-cy="search_word"
              value={props.searchParams.basicSearch}
            />
            <div className="input-group-append">
              <button
                data-cy="search-btn"
                className="btn btn-primary"
                onClick={() => handleSubmit()}
              >
                {translate("design.portlet.search.button")}
              </button>
            </div>
            <div className="input-group-append">
              {size.width < bootStrapGrid.sm ? (
                <button
                  id="filter"
                  className="btn-icon m-1 pb-0 pl-2 pr-2 pt-0"
                  style={{ border: "none", backgroundColor: "white" }}
                  onClick={props.toggleShowSortersAndFilters}
                  aria-label={translate(
                    "design_navigation_open_sorting_and_filtering"
                  )}
                >
                  {props.filtersSelected ? (
                    <img
                      id="filter"
                      src={filtersSelectedIcon}
                      alt=""
                      height="22"
                    />
                  ) : (
                    <img id="filter" src={filterIcon} alt="" height="22" />
                  )}
                </button>
              ) : (
                <button
                  id="filter"
                  data-cy="search-btn2"
                  style={
                    props.filtersSelected
                      ? { backgroundColor: "#c2529f", borderColor: "#c2529f" }
                      : {}
                  }
                  className={
                    props.filtersSelected
                      ? "btn btn-primary"
                      : "btn btn-secondary"
                  }
                  onClick={props.toggleShowSortersAndFilters}
                >
                  {props.filtersSelected
                    ? translate("design_filters_on")
                    : translate("design_filter_and_sort")}
                </button>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default withRouter(BasicSearch);
