import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import DataField from "../../common/DataField";
import { formatDate } from "../../../helpers/helpers";
import { bootStrapGrid } from "../../../constants/constants";

const BasicInfo = props => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();

  const fields = props.data;

  const applicationNumber = (
    <DataField
      title={translate("design.portlet.application.number")}
      value={fields.applicationNumber || "-"}
    />
  );

  const registrationNumber = (
    <DataField
      title={translate("design.portlet.register.number")}
      value={fields.registrationNumber || "-"}
    />
  );

  const filingNumber = (
    <DataField
      title={translate("design.portlet.filing.number")}
      value={fields.filingNumber || "-"}
    />
  );

  const linkToWipo = (
    <DataField
      title={translate("design_link_to_WIPO")}
      value={fields.wipoLink ?  
        (
          <div className="m-0">
            <a href={fields.wipoLink} alt={translate("")}>{fields.registrationNumber}</a>
          </div>
        )
        : "-"}
    />
  );

  const status = (
    <DataField
      title={translate("design.portlet.result.header.status")}
      value={
        translate(
          "design.status." + fields.dossierStatus.toLowerCase()
        ) || "-"
      }
    />
  );

  const oldestPriority =
    fields.priorities.sort((a, b) =>
      a.priorityDate && b.priorityDate ? a.priorityDate.localeCompare(b.priorityDate) : 0
    )[0] || {};

  const priorityDate = (
    <DataField
      title={translate("design.portlet.priority.date")}
      value={
        fields.priorities.length < 1 ? (
          "-"
        ) : (
          <div className="m-0">
            <span>{formatDate(oldestPriority.priorityDate) || "-" }</span>
            <span>
              {oldestPriority.partial
                ? " " + translate("partial_priority")
                : ""}
            </span>
          </div>
        )
      }
    />
  );

  const applicationDate = (
    <DataField
      title={translate("design.portlet.application.date")}
      value={fields.applicationDate || "-"}
    />
  );

  const registerDate = (
    <DataField
      title={translate("design.portlet.registration.date")}
      value={fields.registrationDate || "-"}
    />
  );

  const expiryDate = (
    <DataField
      title={translate("design.portlet.expiry.date")}
      value={fields.expirationDate || "-"}
    />
  );

  const oppositionStartDate = (
    <DataField
      title={translate("design.portlet.opposition.start.date")}
      value={fields.oppositionPeriodStartDate || "-"}
    />
  );

  const oppositionEndDate = (
    <DataField
      title={translate("design.portlet.opposition.end.date")}
      value={fields.oppositionPeriodEndDate || "-"}
    />
  );

  const publicationDate = (
    <DataField
      title={translate("design.portlet.announcement.date")}
      value={fields.defermentExpiration || "-"}
    />
  );

  const mobileView = (
    <div>
      <Row>
        <Col xs={6}>{applicationNumber}</Col>
        <Col xs={6}>{registrationNumber}</Col>
      </Row>
      <Row>
        <Col xs={12}>{status}</Col>
      </Row>
      <Row>
        <Col xs={6}>{applicationDate}</Col>
        <Col xs={6}>{registerDate}</Col>
      </Row>
      <Row>
        <Col xs={6}>{priorityDate}</Col>
        <Col xs={6}>{publicationDate}</Col>
      </Row>
      <Row>
        <Col xs={6}>{oppositionStartDate}</Col>
        <Col xs={6}>{oppositionEndDate}</Col>
      </Row>
      <Row>
        <Col xs={6}>{expiryDate}</Col>
        <Col xs={6}>{linkToWipo}</Col>
        <Col xs={6}>{filingNumber}</Col>
      </Row>
    </div>
  );

  const padView = (
    <div>
      <Row>
        <Col md={3}>
          {status}
          {applicationNumber}
          {registrationNumber}
        </Col>
        <Col md={3}>
          {priorityDate}
          {publicationDate}
          {oppositionStartDate}
        </Col>
        <Col md={3}>
          {applicationDate}
          {registerDate}
          {oppositionEndDate}
        </Col>
        <Col md={3}>
          {expiryDate}
          {linkToWipo}
          {filingNumber}
        </Col>
      </Row>
    </div>
  );

  if (!props.data) {
    return null;
  }

  return (
    <div>
      <h3>{translate("design.portlet.basic.information")}</h3>
      {size.width < bootStrapGrid.sm ? mobileView : padView}
    </div>
  );
};

export default BasicInfo;
