import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const LanguageSelect = (props) => {
  const { dispatch } = useContext(I18nContext);
  const size = UseWindowSize();

  const onLanguageSelect = (e) => {
    document.documentElement.lang = e;
    dispatch({ type: "setLanguage", payload: e });

    const newPath = props.location.pathname.replace( props.pathParams.getLang(), e);
    props.history.push({
      pathname: newPath,
    });
  };

  let rightAlignment = true;
  let textSize = "1.0rem";
  if (size.width < bootStrapGrid.md) {
    rightAlignment = false;
    textSize = "1.4rem";
  }

  let title = "";
  if (document.documentElement.lang === "fi") {
    title = "Suomeksi";
  }
  if (document.documentElement.lang === "sv") {
    title = "På svenska";
  }
  if (document.documentElement.lang === "en") {
    title = "In English";
  }

  return (
    <NavDropdown
      id="language_select"
      aria-haspopup="true"
      data-cy="language"
      alignRight={rightAlignment}
      align="right"
      title={title}
      style={{ fontSize: textSize }}
    >
      <NavDropdown.Item
        role="menuitem"
        data-cy="fi"
        style={{ fontSize: textSize }}
        onSelect={onLanguageSelect}
        eventKey="fi"
        lang="fi"
      >
        Suomeksi
      </NavDropdown.Item>
      <NavDropdown.Item
        role="menuitem"
        data-cy="sv"
        style={{ fontSize: textSize }}
        onSelect={onLanguageSelect}
        eventKey="sv"
        lang="sv"
      >
        På svenska
      </NavDropdown.Item>
      <NavDropdown.Item
        role="menuitem"
        data-cy="en"
        style={{ fontSize: textSize }}
        onSelect={onLanguageSelect}
        eventKey="en"
        lang="en"
      >
        In English
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default withRouter(LanguageSelect);
