import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { Link } from "react-router-dom";

const PageNotFound = props => {
  const { translate } = useContext(I18nContext);
  return (
    <Container>
      <Row className="pt-4 pb-4">
        <Col xs={12} className="pt-2">
          <h2>{translate(props.title)}</h2>
        </Col>
      </Row>
      <Row>
          <Col xs={12}>
              {translate(props.description)}
          </Col>
      </Row>
      <Row>
          <Col>
            <Link data-cy="home" to={"/"}>
                {translate("design_search_link")}
            </Link>
          </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
