const checkLastDigit = (item) => {
  const sum =
    item[6] * 2 +
    item[5] * 4 +
    item[4] * 8 +
    item[3] * 5 +
    item[2] * 10 +
    item[1] * 9 +
    item[0] * 7;
  const remainder = sum % 11;

  if (remainder === 0) {
    if (item[8] !== "0") {
      return false;
    } else {
      return true;
    }
  } else if (remainder === 1) {
    return false;
  } else if (item[8] !== (11 - remainder).toString()) {
    return false;
  }
  return true;
};
const validateBusinessID = (item) => {
  if (item === "") {
    return true;
  }
  let isValid = true;
  const businessID = item.trim();
  const regex = /[0-9]{7}[-][0-9]/;
  if (
    !businessID.match(regex) ||
    !checkLastDigit(businessID) ||
    businessID.length > 9
  ) {
    isValid = false;
  }
  return isValid;
};

const validateClassNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^\d{1,2}(-\d{1,2}){0,1}([,\s]+\d{1,2}(-\d{1,2}){0,1}){0,99}$/i;
  return trimmed.match(regex);
};

const validateApplicationNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^([MDW\d*?]{1,10}([,\s]+[MDW\d*?]{1,10}){0,99})?$/i;
  return trimmed.match(regex);
};

const validateFilingNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^((FIFI|FIDS)\d{15})?$/i;
  return trimmed.match(regex);
};

const validateRegistrationNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^((DM\/|DM){0,1}[\d*?]{1,10}[a-z]?([,\s]+(DM\/|DM){0,1}[\d*?]{1,10}[a-z]?){0,99})?$/i;
  return trimmed.match(regex);
};

export const validateSearchForm = (fields) => {
  let errors = [];
  if (fields.productTitle.length > 999) {
    errors.push("productTitle");
  }
  if (!validateClassNumber(fields.classNumber)) {
    errors.push("classNumber");
  }
  if (!validateApplicationNumber(fields.applicationNumber)) {
    errors.push("applicationNumber");
  }
  if (!validateRegistrationNumber(fields.registrationNumber)) {
    errors.push("registrationNumber");
  }
  if (!validateFilingNumber(fields.filingNumber)) {
    errors.push("filingNumber");
  }
  if (fields.applicantName.length > 999) {
    errors.push("applicantName");
  }
  if (fields.representativeName.length > 999) {
    errors.push("representativeName");
  }
  if (fields.designerName.length > 999) {
    errors.push("designerName");
  }
  if (!validateBusinessID(fields.businessID)) {
    errors.push("businessID");
  }
  return errors;
};
