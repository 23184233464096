import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { formatDate } from "../../helpers/helpers";

const CorrespondenceInfo = props => {
  const { translate } = useContext(I18nContext);
  const regex = /[a-zA-Z0-9]/g;

  return (
    <div className="pb-2">
      <h3>{translate("design.portlet.correspondence")}</h3>
      {props.data.length < 1 ? (
        <span>-</span>
      ) : (
        <div>
          <Row>
            <Col xs={4}>
              <dt>{translate("design.portlet.correspondence.name")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("design.portlet.correspondence.sent.date")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("design.portlet.correspondence.due.date")}</dt>
            </Col>
          </Row>
          {props.data.map(item => (
            <Row key={item.ordinal}>
              <Col xs={4}>
                {item.letterType ? (
                  <dd className="datafield-content">
                    {translate(
                      "design.letter.code." +
                        item.letterType.match(regex).join("")
                    )}
                  </dd>
                ) : (
                  <dd className="datafield-content">-</dd>
                )}
              </Col>
              <Col xs={4}>
                {item.letterCategory === "INCOMING" ? (
                  <dd className="datafield-content">
                    {formatDate(item.receiveDate)}
                  </dd>
                ) : (
                  <dd className="datafield-content">
                    {formatDate(item.sendDate)}
                  </dd>
                )}
              </Col>
              <Col xs={4}>
                {item.dueDate ? (
                  <dd className="datafield-content">
                    {formatDate(item.dueDate)}
                  </dd>
                ) : (
                  <dd className="datafield-content">-</dd>
                )}
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default CorrespondenceInfo;
