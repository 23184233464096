import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../common/Accordion";
import { I18nContext } from "../../../i18n";
import { formatDate } from "../../../helpers/helpers";

const RelatedDossierInfo = props => {
  const { translate } = useContext(I18nContext);

  const accordionContent = (item) => {
    return (
      <div className="pb-3 pt-3">
        <Row className="row pl-4">
          <Col xs={6}>{translate("application_or_opposition_number")}</Col>
          <Col xs={6}>
            {item.applicationNumber}
          </Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("design.portlet.events.filing.date")}</Col>
          <Col xs={6}>{formatDate(item.applicationDate) || "-"}</Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("design.portlet.events.status.date")}</Col>
          <Col xs={6}>{formatDate(item.dossierStatusDate) || "-"}</Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("design.portlet.events.status")}</Col>
          <Col xs={6}>
            {translate(
              "design.event.status." +
                item.dossierType.toLowerCase() +
                "." +
                item.dossierStatus.toLowerCase()
            ) || "-"}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <h3>{translate("design.portlet.events")}</h3>
      {props.data.length < 1 ? (
        <span>-</span>
      ) : (
        props.data.map(item => {
          let translationCode = "";
          if (item.dossierType === "DesignRecordal") {
            translationCode = "design.event.type.designrecordal.";
          }
          if (item.dossierType === "DesignOpposition") {
            translationCode = "design.event.type.designopposition.";
          }
          return (
            <Accordion
              key={item.ordinal}
              title={
                item.dossierSubtype
                  ? translate(
                      translationCode + item.dossierSubtype.toLowerCase()
                    )
                  : translate(
                      "design.event.type." + item.dossierType.toLowerCase()
                    )
              }
              content={accordionContent(item)}
            />
          );
        })
      )}
    </div>
  );
};

export default RelatedDossierInfo;
