import React, { useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import Pagination from "../common/Pagination";
import { formatDate, formatDesignPath } from "../../helpers/helpers";

const TableView = (props) => {
  const { translate } = useContext(I18nContext);
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (currentPage, pageOfItems) => {
    setPageOfItems(pageOfItems);
    props.onChangePage(currentPage);
    window.scrollTo(0, 0);
  };

  const findOldestDate = (list) => {
    return (
      list.sort((a, b) =>
        a.priorityDate && b.priorityDate
          ? a.priorityDate.localeCompare(b.priorityDate)
          : 0
      )[0] || {}
    );
  };

  const goToDesignDetails = ( props, item ) => {
    props.history.push( props.pathParams.buildPath( 'design/' + formatDesignPath(item)));
  };

  const designHeaders = (
    <thead>
      <tr>
        <th aria-colindex="1">
          {translate("design_tableView_application_and_register_number")}
        </th>
        <th aria-colindex="2" className="text-center">
          {translate("design_tableView_title")}
        </th>
        <th aria-colindex="3">{translate("design.portlet.classes")}</th>
        <th aria-colindex="4">
          {translate("design_tableView_periority_and_applicationdate")}
        </th>
        <th aria-colindex="5">
          {translate("design_tableView_registration_and_expirationdate")}
        </th>
        <th aria-colindex="6">{translate("design.portlet.current.status")}</th>
        <th aria-colindex="7">
          {translate("design.portlet.applicant.registered")}
        </th>
      </tr>
    </thead>
  );

  const designBody = (
    <tbody>
      {pageOfItems.map((item, index) => (
        <tr
          className="prh-table-row"
          key={item.dossierId}
          tabindex="0"
          aria-rowindex={index + 1}
          role="row"
          onClick={() => goToDesignDetails( props, item )}
          onKeyUp={(e) =>
            e.keyCode === 13
              ? goToDesignDetails( props, item )
              : null
          }
          title={item.designs[0].title}
        >
          <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
            <div>
              <Link
                className="table-link p-0"
                to={
                  props.pathParams.buildPath( 'design/' + formatDesignPath(item ))
                }
                tabindex="-1"
              >
                {item.applicationNumber || "-"}
              </Link>
            </div>
            <div>{item.registrationNumber || "-"}</div>
          </td>
          <td
            aria-colindex="2"
            data-label="Mallin nimike"
            role="cell"
            className="text-center"
          >
            {item.designs.map((design) => {
              return <div>{design.number + ". " + design.title}</div>;
            })}
          </td>
          <td aria-colindex="3" data-label="Luokkanumero" role="cell">
            {item.locarnos.map((locarno) => {
              return (
                <div>{locarno.classNumber + "-" + locarno.subClassNumber}</div>
              );
            })}
          </td>
          <td
            aria-colindex="4"
            data-label="Etuoikeus ja -hakemispvm"
            role="cell"
          >
            <div>
              {formatDate(findOldestDate(item.priorities).priorityDate) || "-"}
            </div>
            <div>{formatDate(item.applicationDate) || "-"}</div>
          </td>
          <td
            aria-colindex="5"
            data-label="Rekisteröinti- ja erääntymispvm"
            role="cell"
          >
            <div>{formatDate(item.registrationDate) || "-"}</div>
            <div>{formatDate(item.expirationDate) || "-"}</div>
          </td>
          <td aria-colindex="6" data-label="Nykyinen tila" role="cell">
            <div>
              {translate("design.status." + item.dossierStatus.toLowerCase())}
            </div>
          </td>
          <td aria-colindex="7" data-label="Haltia" role="cell">
            <div>{item.applicants.map((item) => item.name).join(" ")}</div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <Container>
      <div data-cy="table" className="table-responsive mt-2">
        <table
          id=""
          role="table"
          aria-colcount="4"
          aria-rowcount="-1"
          className="table table-hover table-stacked-md prh-table-view"
        >
          {designHeaders}
          {designBody}
        </table>
      </div>
      <Row>
        <Col style={{ paddingRight: "0px" }}>
          <Pagination
            items={props.data.results}
            onChangePage={onChangePage}
            pagesOnBar={10}
            pageSize={20}
            currentPage={props.currentPage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(TableView);
