import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Col, Row, Container } from "react-bootstrap";
import Icons from "../../scss/prh-icon-sprite.svg";

const Footer = () => {
  const { translate } = useContext(I18nContext);

  return (
    <footer className="prh-site-footer d-print-none">
      <Container>
        <Row className="align-items-start justify-content-between pt-4">
          <Row className="col-sm-auto align-items-start justify-content-start mb-3">
            <Col className="col-auto pr-2">
              <div className="prh-site-footer__logo"></div>
            </Col>
            <Col className="col-auto">
              <h4>{translate("footer_title")}</h4>
              <p>
                {translate("footer_streetaddress_title")}:{" "}
                {translate("footer_streetaddress")}
              </p>
              <p>
                {translate("footer_postaddress_title")}:{" "}
                {translate("footer_postaddress")}
              </p>
            </Col>
          </Row>
          <Col className="col-sm-auto mb-3">
            <h4>{translate("footer_customer_service")}</h4>
            <p>{translate("footer_customer_service_datetime")}</p>
            <p>{translate("footer_customer_service_telephone")}</p>
          </Col>
          <Col className="col-sm-auto mb-3">
            <h4>{translate("footer_info_title")}</h4>
            <p>
              <a href={translate("footer_privacy_policy_link")}>
                <svg
                  aria-labelledby="privacy_link"
                  className="prh-icon prh-icon--xs"
                  style={{ stroke: "#ffffff" }}
                >
                  <title id="privacy_link">
                    {translate("design_external_link")}
                  </title>
                  <use xlinkHref={`${Icons}#external-link`}></use>
                </svg>
                <span className="menu-text ml-1" style={{ fontSize: "1.1rem" }}>
                  {translate("footer_privacy_policy_title")}
                </span>
              </a>
            </p>
            <p>
              <a href={translate("footer_accessibility_leaflet.link")}>
                <svg
                  aria-labelledby="accessibility_link"
                  className="prh-icon prh-icon--xs"
                  style={{ stroke: "#ffffff" }}
                >
                  <title id="accessibility_link">
                    {translate("design_external_link")}
                  </title>
                  <use xlinkHref={`${Icons}#external-link`}></use>
                </svg>
                <span className="menu-text ml-1" style={{ fontSize: "1.1rem" }}>
                  {translate("footer_accessibility_leaflet")}
                </span>
              </a>
            </p>
          </Col>
        </Row>
        <hr />
        <Row className="align-items-center justify-content-between pb-3">
          <Row className="col-sm-auto align-items-center"></Row>
          <Col className="col-sm-auto align-items-center">
            <small>© {translate("footer_title")}</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
