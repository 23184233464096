import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import Icons from "../../scss/prh-icon-sprite.svg";

const WarningMessage = props => {
  const { translate } = useContext(I18nContext);
  return (
    <div
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      className="alert alert-warning alert-dismissible mb-0"
    >
      <button
        onClick={() => props.clearError()}
        type="button"
        aria-label={translate("design.portlet.close")}
        className="close"
      >
        ×
      </button>
      <svg alt="" className="prh-icon prh-icon--orange">
        <use xlinkHref={`${Icons}#alert-triangle`}></use>
      </svg>
      <strong className="lead">
        {translate(props.errorTitle)}
      </strong>
      <p className="small">
        {translate(props.errorContent)}
      </p>
    </div>
  );
};

export default WarningMessage;
