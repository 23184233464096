import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Link } from "react-router-dom";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const PrhBrand = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();

  return (
    <Link aria-label={translate("design_navigation_home")} to={ props.pathParams.buildPath() }>
      <div className="prh-site-header__title" data-cy="toFrontpage">
        <div className="prh-site-header__logo"></div>
        <h1
          style={
            size.width < bootStrapGrid.sm
              ? { color: "white", fontSize: "1.2rem" }
              : { color: "white", fontSize: "1.8rem" }
          }
        >
          <span className="sub-title">{translate("footer_title")}</span>
          {translate("design_service_title")}
        </h1>
      </div>
    </Link>
  );
};

export default PrhBrand;
