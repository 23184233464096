import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import { formatDate } from "../../../helpers/helpers";


const PublicationsInfo = props => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="pb-2">
      <h3>{translate("design.portlet.publications")}</h3>
      {props.data.length < 1 ? (
        <span>-</span>
      ) : (
        <div>
          <Row>
            <Col xs={4}>
              <dt>{translate("design.portlet.publication.number")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("design.portlet.publication.date")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("design.portlet.publication.kind")}</dt>
            </Col>
          </Row>
          {props.data.map(item => (
            <Row key={item.ordinal}>
              <Col xs={4}>
                {item.gazetteNumber ? (
                  <dd className="datafield-content">{item.gazetteNumber}</dd>
                ) : (
                  <dd className="datafield-content">-</dd>
                )}
              </Col>
              <Col xs={4}>
                {item.publicationDate ? (
                  <dd className="datafield-content">
                    {formatDate(item.publicationDate)}
                  </dd>
                ) : (
                  <dd className="datafield-content">-</dd>
                )}
              </Col>
              <Col xs={4}>
                {item.type ? (
                  <dd className="datafield-content">
                    {translate(
                      "design.publications." + item.type.toLowerCase()
                    )}
                  </dd>
                ) : (
                  <dd className="datafield-content">-</dd>
                )}
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default PublicationsInfo;
