import React, { useContext, useEffect } from "react";
import { I18nContext } from "../i18n";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import DesignDetails from "../components/DesignDetails/DesignDetails";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";

const DesignDetailsPage = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("application_title_designdetails") + " | PRH";

  useEffect(() => {
    props.pathParams.updateLanguage();
  }, []);

  return (
    <>
      <Container fluid="true" className="flex-wrapper m-0 p-0">
        <div>
          <div id="skip">
            <a href="#details_content">{translate("jumplink_to_content")}</a>
          </div>
          {size.width < bootStrapGrid.md ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          <main id="details_content" tabIndex="-1">
            <DesignDetails
              fetchDesign={props.fetchDesign}
              design={props.design}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(DesignDetailsPage);
