export const bootStrapGrid = { sm: 768, md: 992, lg: 1200 };

export const initialSearchParams = {
  productTitle: "",
  applicationNumber: "",
  registrationNumber: "",
  dossierStatus: "",
  designerName: "",
  applicantName: "",
  representativeName: "",
  businessID: "",
  applicationStartDate: "",
  applicationEndDate: "",
  oppositionPeriodStartStartDate: "",
  oppositionPeriodStartEndDate: "",
  publicationDateStartDate: "",
  publicationDateEndDate: "",
  registrationStartDate: "",
  registrationEndDate: "",
  classNumber: "",
  filingNumber: "",
  basicSearch: ""
};

export const validStatusFilters = [
  "DS_Application_filed",
  "DS_Appeal_pending",
  "DS_Registered_wait_publication",
  "DS_Registered_opposition_period_running",
  "DS_Registration_opposed",
  "DS_Registered",
  "DSIR_pending_in_Finland",
  "DSIR_appeal_pending",
  "DSIR_accepted_as_valid_in_Finland_waiting_for_publication",
  "DSIR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "DSIR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "DSIR_valid_in_Finland"
];

export const nonvalidStatusFilters = [
  "DS_Application_withdrawn",
  "DS_Application_dismissed",
  "DS_Application_rejected",
  "DS_Application_rejected_due_to_opposition",
  "DS_Registration_revoked_after_opposition",
  "DS_Ended_withdrawn",
  "DS_Registration_cancelled_court_decision",
  "DS_Expired",
  "DSIR_not_valid_in_Finland_dismissed",
  "DSIR_not_valid_in_Finland_refused",
  "DSIR_rejected",
  "DSIR_not_valid_in_Finland_rejected_on_account_of_an_opposition",
  "DSIR_canceled",
  "DSIR_not_valid_in_Finland_not_renewed",
  "DSIR_removed_from_registration_on_account_of_court_order"
];

export const statusFilters = [
  "DS_Registered",
  "DS_Registration_opposed",
  "DS_Application_filed",
  "DS_Appeal_pending",
  "DS_Application_withdrawn",
  "DS_Registered_opposition_period_running",
  "DS_Expired",
  "DS_Application_rejected",
  "DS_Application_dismissed",
  "DS_Registered_wait_publication",
  "DS_Registration_revoked_after_opposition",
  "DS_Registration_cancelled_court_decision",
  "DS_Ended_withdrawn",
  "DS_Application_rejected_due_to_opposition",
  "DSIR_valid_in_Finland",
  "DSIR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "DSIR_pending_in_Finland",
  "DSIR_accepted_as_valid_in_Finland_waiting_for_publication",
  "DSIR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "DSIR_rejected",
  "DSIR_appeal_pending",
  "DSIR_not_valid_in_Finland_dismissed",
  "DSIR_not_valid_in_Finland_refused",
  "DSIR_not_valid_in_Finland_not_renewed",
  "DSIR_canceled",
  "DSIR_not_valid_in_Finland_rejected_on_account_of_an_opposition",
  "DSIR_removed_from_registration_on_account_of_court_order"
];

export default false;
