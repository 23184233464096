import React from "react";

const DataField = props => {
  return (
    <div data-cy={props.data_cy} className="pb-2">
      <dt className="m-0 p-0">{props.title}</dt>
      <dd className="datafield-content">{props.value}</dd>
    </div>
  );
};

export default DataField;
