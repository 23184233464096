import React, { useState, useContext } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import DataField from "../../common/DataField";
import { bootStrapGrid } from "../../../constants/constants";
import noImage from "../../../scss/styles/images/noimage.png"
import DesignCarousel from "./DsCarousel";

const DetailedInfo = props => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const fields = props.data;
  const applicationNumber = fields.applicationNumber;
  const registerNumber = fields.registrationNumber;
  const [showModal, setShowModal] = useState(false);
  const [currentDesign, setCurrentDesign] = useState(1);
  const [index, setIndex] = useState(0);
  const [registered, setRegistered] = useState(true);
  const [viewCount, setViewCount] = useState(0);

  const getViewCount = (design, reg) => {
    let count = fields.designs[design-1].designViews.filter(view => view.allowPublication === reg).length;
    setViewCount(count)
    return count;
  }

  const handleOpen = (design, view, reg) => {
    setIndex(view-1);
    setCurrentDesign(design);
    setRegistered(reg);
    getViewCount(design, reg);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const number = data => (
    <dl data-cy={props.data_cy} className="pb-2">
      <dt className="m-0 p-0">
        {translate("design") + " " + data.designNumber}
      </dt>
      {data.title && <dd className="datafield-content m-0 p-0" lang="fi">
        {data.title}
      </dd>}
      {data.titleSecondLanguage && <dd className="datafield-content m-0 p-0" lang="sv">
        {data.titleSecondLanguage}
      </dd>}
    </dl>

  );

  const status = data => (
    <DataField
      title={translate("design_status")}
      value={ data ? translate("design_status_" + data) : "-"}
    />
  );

  const registerdViews = data => {
    const show = data.designViews.some((view)=>view.allowPublication);
    return show ? (
      <div data-cy={props.data_cy} className="pb-2">
        <dt className="m-0 p-0">{translate("design_registerd_views")}</dt>
        <dd className="datafield-content">{data.designViews.map(view => view.allowPublication ?
        <a
          className="p-1"
          key={"link"+applicationNumber+data.designNumber+view.viewNumber}
          alt={translate("design_registered_views_link")}
          onClick={(e) => { e.preventDefault(); handleOpen(data.designNumber, view.ordinal, true);}} style={{ border: "none" }} href="/">
            <img className="p-1"
            src={ view.thumbnailUrl }
            width={100}
            alt=""
            onError={(e)=>{e.target.onerror = null; e.target.src=noImage;}}
            />
        </a> : "")}</dd>
      </div>
    ) : "";
  };

  const notRegisterdViews = data => {
    const show = data.designViews.some((view)=>!view.allowPublication);
    return show ? (
      <div data-cy={props.data_cy} className="pb-2">
        <dt className="m-0 p-0">{translate("design_not_registerd_views")}</dt>
        <dd className="datafield-content">{data.designViews.map(view => !view.allowPublication ?
        <a
          className="p-1"
          key={"link"+applicationNumber+data.designNumber+view.viewNumber}
          alt={translate("design_not_registered_views_link")}
          onClick={(e) => { e.preventDefault(); handleOpen(data.designNumber, view.ordinal, false);}} style={{ border: "none" }} href="/">
            <img className="p-1"
            src={ view.thumbnailUrl }
            width={100}
            alt=""
            onError={(e)=>{e.target.onerror = null; e.target.src=noImage;}}
            />
        </a> : "")}</dd>
      </div>
    ) : "";
  };

  const imageModal = (
    <Modal show={showModal} onHide={handleClose} size="lg" dialogClassName="modal-height">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center pb-3">
        <DesignCarousel
          data={fields}
          index={index}
          currentDesign={currentDesign}
          viewCount={viewCount}
          registered={registered}
        />
      </Modal.Body>
    </Modal>
  );

  const mobileView = design => (
    <div
    className="border border-top-0 border-left-0 border-right-0 border-gray mb-2"
    key={applicationNumber+design.designNumber}>
      <Row>
        <Col xs={12}>{number(design)}</Col>
      </Row>
      <Row>
        <Col xs={12}>{status(design.designStatus)}</Col>
      </Row>
      <Row>
        <Col xs={12}>{registerdViews(design)}</Col>
      </Row>
      <Row>
        <Col xs={12}>{notRegisterdViews(design)}</Col>
      </Row>
    </div>
  );
  const padView = (design) => {
  return (
    <div
    className="border border-top-0 border-left-0 border-right-0 border-gray mb-2"
    key={applicationNumber+design.designNumber}>
      <Row>
        <Col md={3}>{number(design)}</Col>
        <Col md={3}>{status(design.designStatus)}</Col>

      </Row>
      <Row>
        <Col md={12}>{registerdViews(design)}</Col>
      </Row>
      <Row>
        <Col md={12}>{notRegisterdViews(design)}</Col>
      </Row>
    </div>
  )};
  return (
    <div>
      <h3>{translate("design.portlet.designs")}</h3>
      {imageModal}
      {fields.designs.map(design => size.width < bootStrapGrid.sm ? mobileView(design) : padView(design))}
    </div>
  );
};

export default DetailedInfo;
