import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import LanguageSelect from "./LanguageSelect";
import { Link } from "react-router-dom";
import Icons from "../../scss/prh-icon-sprite.svg";

const NavMenu = ( props ) => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Link to={ props.pathParams.buildPath() }>
        <Row className="pt-3 pb-3">
          <Col className="ml-3">
            <svg
              alt={translate("design_navigation_home")}
              className="prh-icon prh-icon--xs"
              style={{ stroke: "#ffffff" }}
            >
              <use xlinkHref={`${Icons}#home`}></use>
            </svg>
            <span className="menu-text ml-1" data-cy="home">
              {translate("design_frontpage")}
            </span>
          </Col>
        </Row>
      </Link>
      <Link to={ props.pathParams.buildPath( 'search' )}>
        <Row className="pt-3 pb-3">
          <Col className="">
            <span className="menu-text" data-cy="search">
              {translate("design.portlet.advanced.search")}
            </span>
          </Col>
        </Row>
      </Link>
      <Row className="pt-3 pb-3">
        <a href={translate("design.portlet.gazettes.link")}>
          <Col className="ml-3">
            <svg
              aria-labelledby="gazette_link"
              className="prh-icon prh-icon--xs"
              style={{ stroke: "#ffffff" }}
            >
              <title id="gazette_link">
                {translate("design_external_link")}
              </title>
              <use xlinkHref={`${Icons}#external-link`}></use>
            </svg>
            <span className="menu-text ml-1" data-cy="gazettes">
              {translate("design.portlet.gazettes")}
            </span>
          </Col>
        </a>
      </Row>
      <Row className="pt-3 pb-3">
        <a href={translate("design.portlet.instructions.link")}>
          <Col className="ml-3">
            <svg
              aria-labelledby="instructions_link"
              className="prh-icon prh-icon--xs"
              style={{ stroke: "#ffffff" }}
            >
              <title id="instructions_link">
                {translate("design_external_link")}
              </title>
              <use xlinkHref={`${Icons}#external-link`}></use>
            </svg>
            <span className="menu-text ml-1" data-cy="instructions">
              {translate("menu_instructions")}
            </span>
          </Col>
        </a>
      </Row>
      <Navbar className="navbar navbar-dark navbar-expand-md">
        <Nav>
          <LanguageSelect pathParams = { props.pathParams }/>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavMenu;
