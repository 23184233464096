import * as React from "react";
import Masonry from "react-masonry-component";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { formatDesignPath } from "../../helpers/helpers";
import Pagination from "../common/Pagination";
import { bootStrapGrid } from "../../constants/constants";
import noImage from "../../scss/styles/images/noimage.png";
import fi from "../../i18n/messages_design_fi.json";
import sv from "../../i18n/messages_design_sv.json";
import en from "../../i18n/messages_design_en.json";

const masonryOptions = {
  columnWidth: 79,
  gutter: 5,
  isFitWidth: true,
  itemSelector: ".grid-item",
  transitionDuration: 0,
  isAnimated: false,
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };

class MasonryView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageOfItems: [],
      itemsWithImg: [],
    };
  }

  componentDidMount = () => {
    let itemsWithImg = [];
    this.props.data.results.forEach((item) => {
      item.designs.forEach((design) => {
        design.url = design.dominantViewMediumThumbnailUrl;
        design.applicationNumber = item.applicationNumber;
        design.registerNumber = item.registerNumber;
        design.id = item.dossierId;
        itemsWithImg.push(design);
      });
    });
    this.setState({ itemsWithImg: itemsWithImg });
  };

  onChangePage = (currentPage, newPageOfItems) => {
    this.setState({ pageOfItems: newPageOfItems });
    this.props.onChangePage(currentPage, "masonry");
    window.scrollTo(0, 0);
  };

  render() {
    let pagesOnBar = 10;
    if (window.innerWidth < bootStrapGrid.md) {
      pagesOnBar = 5;
    }
    const childElements = this.state.pageOfItems.map((element) => {
      return (
        <Link
          key={"link" + element.id + element.number + element.ordinal}
          className="p-0"
          to={{
            pathname: this.props.pathParams.buildPath( 'design/' + formatDesignPath( element ))
          }}
        >
          <img
            key={"img" + element.id + element.number + element.ordinal}
            className="img-fluid grid-item"
            src={element.url}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = noImage;
            }}
            alt=""
          />
        </Link>
      );
    });
    return (
      <Container>
        {this.state.itemsWithImg.length < 1 ? (
          <Row className="pt-4">
            <Col style={{ textAlign: "center" }}>
              {document.documentElement.lang === "fi" ? (
                <span>{fi.masonry_no_images}</span>
              ) : document.documentElement.lang === "sv" ? (
                <span>{sv.masonry_no_images}</span>
              ) : (
                <span>{en.masonry_no_images}</span>
              )}
            </Col>
          </Row>
        ) : (
          <div>
            <Masonry
              className={"masonry"}
              elementType={"div"}
              options={masonryOptions}
              disableImagesLoaded={false}
              updateOnEachImageLoad={false}
              imagesLoadedOptions={imagesLoadedOptions}
            >
              {childElements}
            </Masonry>
            <Row>
              <Col style={{ paddingRight: "0px" }}>
                <Pagination
                  items={this.state.itemsWithImg}
                  onChangePage={this.onChangePage}
                  pagesOnBar={pagesOnBar}
                  pageSize={100}
                  currentPage={this.props.currentPage}
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

export default withRouter(MasonryView);
