import React, { useContext, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import { allDossierStatuses, registeredDossierStatuses } from "./FormConstants";
import { initialSearchParams } from "../../constants/constants";
import { validateSearchForm } from "./formValidators";
import DatePick from "../common/DatePicker";
import ToolTip from "../common/ToolTip";
import { format } from "date-fns";

const SearchForm = (props) => {
  const { translate } = useContext(I18nContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const fields = props.searchParams;

  let radioValue = "";
  if (
    fields.dossierStatus.length < 1 ||
    fields.dossierStatus === allDossierStatuses ||
    fields.dossierStatus === "all"
  ) {
    radioValue = "all";
  } else {
    radioValue = "registered";
  }

  const ref = React.createRef();
  const handleReset = () => {
    props.setSearchParams(initialSearchParams);
  };

  const handleChange = (event) => {
    const newSearchParams = {
      ...props.searchParams,
      [event.target.name]: event.target.value,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleDateChange = (id, date) => {
    let isoDate = date ? format(date, "yyyy-MM-dd") : "";
    const newSearchParams = {
      ...props.searchParams,
      [id]: isoDate,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleFocusOut = () => {
    setValidationErrors(validateSearchForm(fields));
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      setValidationErrors(validateSearchForm(fields));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validationErrors.length > 0) {
      document.getElementById(validationErrors[0]).focus();
    } else {
      const dossierStatuses =
        fields.dossierStatus === "registered"
          ? registeredDossierStatuses
          : allDossierStatuses;

      const searchParams = fields;
      searchParams.dossierStatus = dossierStatuses;
      searchParams.basicSearch = "";
      props.setSearchParams(searchParams);
      props.fetchSearchResults(searchParams, "design");
      props.history.push({
        pathname: props.pathParams.buildPath( 'results' ),
        state: { editable: true },
      });
    }
  };

  const validProductTitle = !validationErrors.includes("productTitle");
  const validBusinessID = !validationErrors.includes("businessID");
  const validApplicationNumber = !validationErrors.includes(
    "applicationNumber"
  );
  const validFilingNumber = !validationErrors.includes("filingNumber");
  const validApplicantName = !validationErrors.includes("applicantName");
  const validRepresentativeName = !validationErrors.includes(
    "representativeName"
  );
  const validDesignerName = !validationErrors.includes("designerName");
  const validClassNumber = !validationErrors.includes("classNumber");
  const validRegistrationNumber = !validationErrors.includes(
    "registrationNumber"
  );

  return (
    <div className="prh-form">
      <div className="prh-form__container">
        <section className="prh-section-borders">
          <h2>{translate("design_advanced_search_title")}</h2>
          <Form onSubmit={handleSubmit} ref={ref}>
            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="productTitle">
                  {translate("design.portlet.product.title")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_product_title")}
                  id="productTitleTooltip"
                />
              </Row>
              <Form.Control
                id="productTitle"
                name="productTitle"
                className={
                  validProductTitle ? "border border-dark" : "is-invalid"
                }
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                value={fields.productTitle}
                aria-describedby="productTitleTooltip"
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="classNumber">
                  {translate("design.portlet.classes")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_classes")}
                  id="classNumberTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validClassNumber ? "border border-dark" : "is-invalid"
                }
                aria-describedby={
                  validClassNumber ? "classNumberTooltip" : "classNumberError"
                }
                aria-invalid={!validClassNumber}
                id="classNumber"
                name="classNumber"
                value={fields.classNumber}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
                data-cy="classNumber"
              />
              {validClassNumber ? null : (
                <div
                  id="classNumberError"
                  className="invalid-feedback"
                  role="alert"
                >
                  {translate("design_classes_warning")}
                </div>
              )}
            </Form.Group>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label htmlFor="applicationNumber">
                      {translate("design.portlet.application.number")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "design_infotext_searchForm_applicationNumber"
                      )}
                      id="applicationNumberTooltip"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validApplicationNumber
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby={
                      validApplicationNumber
                        ? "applicationNumberTooltip"
                        : "applicationNumberError"
                    }
                    aria-invalid={!validApplicationNumber}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    id="applicationNumber"
                    name="applicationNumber"
                    value={fields.applicationNumber}
                    data-cy="application_number"
                  />
                  {validApplicationNumber ? null : (
                    <div
                      id="applicationNumberError"
                      className="invalid-feedback"
                      role="alert"
                    >
                      {translate("design_application_number_warning")}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label htmlFor="registrationNumber">
                      {translate("design.portlet.register.number")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "design_infotext_searchForm_registrationNumber"
                      )}
                      id="registrationNumberTooltip"
                      placement="bottom"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validRegistrationNumber
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby={
                      validRegistrationNumber
                        ? "registrationNumberTooltip"
                        : "registrationNumberError"
                    }
                    aria-invalid={!validRegistrationNumber}
                    id="registrationNumber"
                    name="registrationNumber"
                    value={fields.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    type="text"
                    data-cy="registration_number"
                  />
                  {validRegistrationNumber ? null : (
                    <div
                      id="registrationNumberError"
                      className="invalid-feedback"
                      role="alert"
                    >
                      {translate("design_register_number_warning")}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="filingNumber">
                  {translate("design_acceptance_reference")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_filingNumber")}
                  id="filingNumberTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validFilingNumber ? "border border-dark" : "is-invalid"
                }
                id="filingNumber"
                name="filingNumber"
                value={fields.filingNumber}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                aria-describedby={
                  validFilingNumber
                    ? "filingNumberTooltip"
                    : "filingNumberError"
                }
                aria-invalid={!validFilingNumber}
                type="text"
                data-cy="filing_number"
              />
              {validFilingNumber ? null : (
                <div
                  id="filingNumberError"
                  className="invalid-feedback"
                  role="alert"
                >
                  {translate("design_filing_number_warning")}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <fieldset aria-describedby="dossierStatusTooltip">
                <legend
                  style={{
                    fontSize: "1rem",
                    width: "auto",
                    marginBottom: "0rem",
                  }}
                >
                  <Col xs={12}>
                    <Row className="align-items-center">
                      {translate("design.portlet.current.status")}
                      <ToolTip
                        text={translate(
                          "design_infotext_searchForm_dossierStatus"
                        )}
                        id="dossierStatusTooltip"
                      />
                    </Row>
                  </Col>
                </legend>
                <Form.Check
                  type="radio"
                  name="dossierStatus"
                  id="dossierStatusRegistered"
                  value="registered"
                  label={translate("design.portlet.preselect.status.active")}
                  onChange={handleChange}
                  checked={radioValue === "registered"}
                />

                <Form.Check
                  type="radio"
                  name="dossierStatus"
                  id="dossierStatusAll"
                  value="all"
                  label={translate("design.portlet.preselect.status.all")}
                  onChange={handleChange}
                  checked={radioValue === "all"}
                />
              </fieldset>
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="applicantName">
                  {translate("design.portlet.applicant")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_applicantName")}
                  id="applicantNameTooltip"
                />
              </Row>
              <Form.Control
                aria-describedby="applicantNameTooltip"
                id="applicantName"
                name="applicantName"
                className={
                  validApplicantName ? "border border-dark" : "is-invalid"
                }
                value={fields.applicantName}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="representativeName">
                  {translate("design.portlet.representative")}
                </Form.Label>
                <ToolTip
                  text={translate(
                    "design_infotext_searchForm_representativeName"
                  )}
                  id="representativeNameTooltip"
                />
              </Row>
              <Form.Control
                aria-describedby="representativeNameTooltip"
                id="representativeName"
                name="representativeName"
                className={
                  validRepresentativeName ? "border border-dark" : "is-invalid"
                }
                value={fields.representativeName}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="designerName">
                  {translate("design.portlet.designer")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_designerName")}
                  id="designerNameTooltip"
                />
              </Row>
              <Form.Control
                aria-describedby="designerNameTooltip"
                id="designerName"
                name="designerName"
                className={
                  validDesignerName ? "border border-dark" : "is-invalid"
                }
                value={fields.designerName}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="businessID">
                  {translate("search_business_id")}
                </Form.Label>
                <ToolTip
                  text={translate("design_infotext_searchForm_businessID")}
                  id="businessIDTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validBusinessID ? "border border-dark" : "is-invalid"
                }
                aria-describedby={
                  validBusinessID ? "businessIDTooltip" : "businessIDError"
                }
                aria-invalid={!validBusinessID}
                id="businessID"
                name="businessID"
                value={fields.businessID}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
                data-cy="business_id"
              />
              {validBusinessID ? null : (
                <div
                  id="businessIDError"
                  className="invalid-feedback"
                  role="alert"
                >
                  {translate("design_businessID_warning")}
                </div>
              )}
            </Form.Group>

            <fieldset style={{ width: "100%" }}>
              <legend
                style={{
                  fontSize: "1rem",
                  width: "auto",
                  marginBottom: "0rem",
                }}
              >
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("design.portlet.application.date")}
                    <ToolTip
                      text={translate(
                        "design_infotext_searchForm_applicationStartDate"
                      )}
                      id="applicationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>

              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="applicationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="applicationDateTooltip"
                      id="applicationStartDate"
                      name="applicationStartDate"
                      value={
                        fields.applicationStartDate
                          ? new Date(fields.applicationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="applicationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="applicationDateTooltip"
                      id="applicationEndDate"
                      name="applicationEndDate"
                      value={
                        fields.applicationEndDate
                          ? new Date(fields.applicationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <fieldset style={{ width: "100%" }}>
              <legend
                style={{
                  fontSize: "1rem",
                  width: "auto",
                  marginBottom: "0rem",
                }}
              >
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("design.portlet.registration.date")}
                    <ToolTip
                      text={translate(
                        "design_infotext_searchForm_registrationStartDate"
                      )}
                      id="registrationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="registrationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="registrationDateTooltip"
                      id="registrationStartDate"
                      name="registrationStartDate"
                      value={
                        fields.registrationStartDate
                          ? new Date(fields.registrationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="registrationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="registrationEndDateTooltip"
                      id="registrationEndDate"
                      name="registrationEndDate"
                      value={
                        fields.registrationEndDate
                          ? new Date(fields.registrationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <fieldset style={{ width: "100%" }}>
              <legend
                style={{
                  fontSize: "1rem",
                  width: "auto",
                  marginBottom: "0rem",
                }}
              >
                <Col xs={12}>
                  <Row className="align-items-center">
                    <Form.Label>
                      {translate("design.portlet.publication.date")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "design_infotext_searchForm_publicationDate"
                      )}
                      id="publicationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="publicationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="publicationDateTooltip"
                      id="publicationStartDate"
                      name="publicationStartDate"
                      value={
                        fields.publicationStartDate
                          ? new Date(fields.publicationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="publicationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="publicationDateTooltip"
                      id="publicationEndDate"
                      name="publicationEndDate"
                      value={
                        fields.publicationEndDate
                          ? new Date(fields.publicationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <Row className="pb-4">
              <Col xs={6}>
                <Button block type="submit" data-cy="submit">
                  {translate("design.portlet.search.button")}
                </Button>
              </Col>
              <Col xs={6}>
                <Button block onClick={handleReset} data-cy="reset">
                  {translate("design.portlet.clear.button")}
                </Button>
              </Col>
            </Row>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default withRouter(SearchForm);
