import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { I18nContext } from "../../i18n";
import "react-datepicker/dist/react-datepicker.css";

const DatePick = (props) => {
  const [startDate, setStartDate] = useState(props.value);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setStartDate(props.value);
  }, [props.value]);

  return (
    <div className="alfame-datepicker-wrapper">
      <DatePicker
        name={props.name}
        dateFormat={["dd.MM.yyyy", "ddMMyyyy"]}
        selected={startDate}
        onChange={(date) => props.handleChange(props.id, date)}
        className="form-control border border-dark"
        placeholderText={translate("design_date")}
      />
    </div>
  );
};

export default DatePick;
