import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Navbar, Nav, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import PrhBrand from "./PrhBrand";
import LanguageSelect from "./LanguageSelect";
import Icons from "../../scss/prh-icon-sprite.svg";

const ExpandedNavbar = (props) => {
  const { translate } = useContext(I18nContext);

  return (
    <header className="prh-site-header">
      <div className="container prh-site-header__container pt-2">
        <Navbar expand="true" className="navbar navbar-dark navbar-expand-md">
          <Nav style={{ width: "100%" }}>
            <Container
              fluid="true"
              className="pl-3 pr-3"
              style={{ width: "100%" }}
            >
              <Row>
                <Col md={8} className="pl-0">
                  <PrhBrand pathParams = { props.pathParams }/>
                </Col>
                <Col md={4}>
                  <LanguageSelect pathParams = { props.pathParams }/>
                </Col>
                <Col md={12} className="mt-0 pt-0">
                  <Row style={{ justifyContent: "space-between" }}>
                    <Col
                      xs="auto"
                      className={
                        props.activeLink === "Home" ? "active-link" : ""
                      }
                    >
                      <Link data-cy="home" to={ props.pathParams.buildPath() }>
                        <svg
                          alt={translate("design_navigation_home")}
                          className="prh-icon prh-icon--xs"
                          style={{ stroke: "#ffffff" }}
                        >
                          <use xlinkHref={`${Icons}#home`}></use>
                        </svg>
                        <span
                          className={
                            props.activeLink === "Home"
                              ? "active-menu-text ml-1"
                              : "menu-text ml-1"
                          }
                          style={{ fontSize: "1.1rem" }}
                        >
                          {translate("design_frontpage")}
                        </span>
                      </Link>
                    </Col>
                    <Col
                      xs="auto"
                      className={
                        props.activeLink === "Search" ? "active-link" : ""
                      }
                    >
                      <Link data-cy="search" to={ props.pathParams.buildPath( 'search' )}>
                        <span
                          className={
                            props.activeLink === "Search"
                              ? "active-menu-text ml-1 mr-1"
                              : "menu-text ml-1 mr-1"
                          }
                          style={{ fontSize: "1.1rem" }}
                        >
                          {translate("design.portlet.advanced.search")}
                        </span>
                      </Link>
                    </Col>
                    <Col xs="auto">
                      <a
                        data-cy="gazettes"
                        href={translate("design.portlet.gazettes.link")}
                      >
                        <svg
                          aria-labelledby="gazette_link"
                          className="prh-icon prh-icon--xs"
                          style={{ stroke: "#ffffff" }}
                        >
                          <title id="gazette_link">
                            {translate("design_external_link")}
                          </title>
                          <use xlinkHref={`${Icons}#external-link`}></use>
                        </svg>
                        <span
                          className="menu-text ml-1"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {translate("design.portlet.gazettes")}
                        </span>
                      </a>
                    </Col>
                    <Col xs="auto">
                      <a
                        data-cy="instructions"
                        href={translate("design.portlet.instructions.link")}
                      >
                        <svg
                          aria-labelledby="instructions_link"
                          className="prh-icon prh-icon--xs"
                          style={{ stroke: "#ffffff" }}
                        >
                          <title id="instructions_link">
                            {translate("design_external_link")}
                          </title>
                          <use xlinkHref={`${Icons}#external-link`}></use>
                        </svg>
                        <span
                          className="menu-text ml-1"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {translate("menu_instructions")}
                        </span>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Nav>
        </Navbar>
      </div>
    </header>
  );
};

export default ExpandedNavbar;
